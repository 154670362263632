<template>
    <div class="box">
        <h2>我的购物车</h2>
        <div class="table" v-for="item in cartList" :key="this">
            <el-table :data="item.cartList" ref="multipleTable" @selection-change="setCheck" :header-cell-style="{ background: 'rgb(250,250,250)', color: '#333333' }" class="top1">
                <el-table-column type="selection" width="50px"> </el-table-column>
                <el-table-column prop="sku_image" label="商品图片" align="center">
                    <template slot-scope="scope">
                      <a :href="'/sku-'+scope.row.goods_id">
                        <img :src="$img(scope.row.sku_images, { size: 'small' })" min-width="70" height="70" />
                      </a>
                    </template>
                </el-table-column>
                <el-table-column prop="goods_name" label="商品名称" align="center">
                  <template slot-scope="scope">
                    <a :href="'/sku-'+scope.row.goods_id">{{ scope.row.goods_name }}</a>
                  </template>
                </el-table-column>
                <el-table-column prop="sku_no" width="150px" label="国际条码" align="center"> </el-table-column>
                <el-table-column prop="goods_no" label="商品货号" align="center"> </el-table-column>
                <el-table-column prop="color_name" label="商品颜色" align="center"> </el-table-column>
                <el-table-column prop="brand_name" label="品牌" align="center"> </el-table-column>
                <el-table-column prop="total" label="整箱数量" align="center"> </el-table-column>
                <el-table-column prop="stock" label="库存" align="center"> </el-table-column>
                <el-table-column prop="member_price" label="您的价格" align="center"> </el-table-column>
				 <!-- <el-table-column prop="goods_stock_alarm" label="预警数量" align="center"> </el-table-column> -->
                <el-table-column prop="num" width="150px" label="订购数量" align="center">
                    <template slot-scope="scope">
                        <div class="cart-flex">
                            <!-- <el-input-number size="mini" :step="1" :min="0" @change="numChange()" v-model="scope.row.buyed_num"></el-input-number> -->
                            <el-input-number size="mini" v-model="scope.row.num" @change="calculationTotalPrice()" :step="1" :min="0"></el-input-number>
                        </div>
						<div style="color: #ED6030;" v-if="scope.row.stock < scope.row.goods_stock_alarm">库存紧张</div> <!-- 库存小于预警值时显示-->
                    </template>
					
					
                </el-table-column>
                <el-table-column label="小计" align="center">
                    <template slot-scope="scope">
                        {{ (scope.row.num * scope.row.member_price).toFixed(2) }}
                    </template>
                </el-table-column>
                <el-table-column prop="sku_image" label="操作" align="center">
                    <template slot-scope="scope">
                        <span class="delete" @click="deleteCart(scope.row, scope.$index)">删除</span>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="controller">
            <div class="controller-left">
                <!-- <el-checkbox :value="isAll">全选</el-checkbox> -->
                <el-button class="control-btn" @click="collect">加入收藏夹</el-button>
                <el-button class="control-btn delete" @click="deleteCartSelected()">删除</el-button>
                <div class="member_price">
                    合计：<span>￥{{ totalPrice }}</span
                    >元
                </div>
            </div>
            <div class="controller-right">
                <el-button class="submit-btn" @click="settlement(0)" :disabled="!checkedGoods.length">提交订单</el-button>
                <el-button class="submit-btn green" @click="settlement(1)" :disabled="!checkedGoods.length">提交预订单</el-button>
            </div>
        </div>
    </div>
</template>


<script>
import { cartList, editNumber } from "@/api/goods/cart"
import { addCollect } from "@/api/goods/goods_collect"
import { mapGetters } from "vuex"
import {minimumAmount} from "@/api/website";

export default {
    name: "cart",
    components: {},
    data() {
        return {
            cartList: [], // 购物车
            checkAll: false,
            totalPrice: "0.00",
            totalCount: 0,
            invalidGoods: [], // 失效商品集合
            loading: true,
            modifyNum: 1, // 防止数量跳动
            checkedGoods: [],
            cart_id: "",
            num: "",
            sumPrice: "0.00",
            miniAmount: 0,
        }
    },
    created() {
        this.getCartList();
        this.getMinimumAmount();
    },
    computed: {
        ...mapGetters(["defaultGoodsImage"])
    },
    mounted() {
        let self = this
        setTimeout(function () {
            self.yes = false
        }, 300)
    },
	
    methods: {
        // 提交订单
        actionOrder() {
            let sku_lists = []
            this.skuData.forEach((item) => {
                if (item.buyed_num > 0) {
                    sku_lists.push({ sku_id: item.sku_id, num: item.buyed_num })
                }
            })
            // console.log(sku_lists);
            if (sku_lists.length > 0) {
                var data = {
                    sku_lists: JSON.stringify(sku_lists)
                }
                this.$store.dispatch("order/setOrderCreateData", data)
                this.$router.push({
                    path: "/payment?type=0"
                })
            }
        },
        // 结算
        settlement(type = 0) {
          // let cart_ids = []
          // this.checkedGoods.forEach(item => {
          //         cart_ids.push({ cart_id: item.cart_id, num: item.num })
          // })
          // console.log(cart_ids);
          // if (cart_ids.length > 0) {
          //     var data = {
          //         cart_ids: JSON.stringify(cart_ids)
          //     }
          //     this.$store.dispatch("order/setOrderCreateData", data)
          //     this.$router.push({
          //         path: "/payment?type=0"
          //     })
          // }
          //
          //金额不足1000不能创建订单
          if (this.totalPrice<this.miniAmount) {
            this.$message({
              message: "你购买的商品没有达到本店最低限购金额"+this.miniAmount+"元，不能提交订单！",
              type: "warning"
            })
            return;
          }
          //如果是非预定订单，需要检查库存
          if (type == 0) {
            let dopay = true;
            for (var item of this.checkedGoods) {
              if (item.stock < item.num) {
                this.$message({
                  message: "商品'" + item.sku_name + "'库存不足",
                  type: "warning"
                })
                return;
              }
            }
          }
          if (this.totalCount > 0) {
            console.log(this.checkedGoods)
            let cart_ids = []
            let cart_nums = []
            this.checkedGoods.forEach((item) => {
              cart_ids.push(item.cart_id)
            })
            this.checkedGoods.forEach((item) => {
              cart_nums.push(item.num)
            })

            var data = {
              cart_ids: cart_ids.toString(),
              cart_nums: cart_nums.toString()
            }
            this.$store.dispatch("order/setOrderCreateData", data)
            this.$router.push({
              path: "/payment?type=" + type
            })
          }
        },
        collect() {
            this.checkedGoods.forEach((cart, index) => {
                console.log(cart)
                addCollect({
                    sku_id: cart.sku_id,
                    goods_id: cart.goods_id
                }).then((res) => {
                    console.log(res)
                })
            })

            this.$message("收藏成功")
        },
        // 选择
        setCheck(e) {
            this.checkedGoods = e
            // console.log(e)
            let sum = 0
            let totalPrice = 0
            for (let i in e) {
                sum = e[i].num * e[i].member_price
                totalPrice = totalPrice + sum
            }
            this.totalPrice = totalPrice.toFixed(2)
            console.log(this.totalPrice)
            // this.calculationTotalPrice()
        },
        // 获取购物车数据
        getCartList() {
            cartList({})
                .then((res) => {
                    if (res.code >= 0 && res.data.length) {
                        this.handleCartList(res.data)
                    }
                    this.loading = false
                })
                .catch((res) => {
                    this.loading = false
                })
        },
        // 处理购物车数据结构
        handleCartList(data) {
            this.invalidGoods = []
            this.cartList = []
            var temp = {}
            data.forEach((item, index) => {
                if (item.goods_state == 1) {
                    item.checked = true
                    if (temp["site_" + item.site_id] != undefined) {
                        temp["site_" + item.site_id].cartList.push(item)
                    } else {
                        temp["site_" + item.site_id] = {
                            siteId: item.site_id,
                            siteName: item.site_name,
                            checked: true,
                            cartList: [item]
                        }
                    }
                } else {
                    this.invalidGoods.push(item)
                }
            })

            this.invalidGoods.forEach((v) => {
                if (v.sku_spec_format) {
                    v.sku_spec_format = JSON.parse(v.sku_spec_format)
                    // console.log(v.sku_spec_format)
                } else {
                    v.sku_spec_format = []
                }
            })
            Object.keys(temp).forEach((key) => {
                this.cartList.push(temp[key])
            })
            this.calculationTotalPrice()
            this.cartList.forEach((v) => {
                v.cartList.forEach((k) => {
                    if (k.sku_spec_format) {
                        k.sku_spec_format = JSON.parse(k.sku_spec_format)
                    } else {
                        k.sku_spec_format = []
                    }
                })
            })
        },
        // 计算购物车总价
        calculationTotalPrice() {
            if (this.cartList.length) {
                let totalPrice = 0,
                    totalCount = 0,
                    siteAllElectionCount = 0
                console.log("购物车:",this.cartList)
                this.cartList.forEach((siteItem) => {
                    let siteGoodsCount = 0
                    siteItem.cartList.forEach((item) => {
                        if(this.checkedGoods.length > 0) {
                          for (var i in this.checkedGoods) {
                            if (item.cart_id == this.checkedGoods[i].cart_id) {
                              siteGoodsCount += 1
                              totalCount += 1
                              totalPrice += this.checkedGoods[i].member_price * this.checkedGoods[i].num
                            }
                          }
                        } else {
                          if (item.checked) {
                            siteGoodsCount += 1
                            totalCount += 1
                            totalPrice += item.member_price * item.num
                          }
                        }
                        // console.log(item.cart_id)
                        console.log(totalPrice)
                        editNumber({
                            cart_id: item.cart_id,
                            num: item.num
                        }).then((res) => {
                            if (res.code >= 0 && res.data) {
                                console.log(res)
                                // this.setCheck()
                            }
                        })
                    })
                    if (siteItem.cartList.length == siteGoodsCount) {
                        siteItem.checked = true
                        siteAllElectionCount += 1
                    } else {
                        siteItem.checked = false
                    }
                })
                this.totalPrice = totalPrice.toFixed(2)
                this.totalCount = totalCount
                this.checkAll = this.cartList.length == siteAllElectionCount
            } else {
                this.totalPrice = "0.00"
                this.totalCount = 0
            }
            this.modifyNum = 1
        },
        // 删除单个
        deleteCart(cart, cartIndex) {
            this.$confirm("确定要删除该商品吗？", "提示信息", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(() => {
                this.$store
                    .dispatch("cart/delete_cart", {
                        cart_id: cart.cart_id
                    })
                    .then((res) => {
                        if (res.code >= 0) {
                            this.cartList[0].cartList.splice(cartIndex, 1)
                            if (this.cartList[0].cartList.length == 0) this.cartList.splice(0, 1)
                            this.calculationTotalPrice()
                            this.$message({
                                type: "success",
                                message: "删除成功"
                            })
                        } else {
                            this.$message({
                                message: res.message,
                                type: "warning"
                            })
                        }
                    })
                    .catch((err) => {
                        this.$message.error(err.message)
                    })
            })
        },
        // 删除选择的购物车
        deleteCartSelected() {
            var cartIds = []
            var selectedItem = []
            // this.cartList.forEach((siteItem, siteIndex) => {
            // 	siteItem.cartList.forEach((item, cartIndex) => {
            // 		if (item.checked) {
            // 			cartIds.push(item.cart_id)
            // 			selectedItem.push({
            // 				siteIndex: siteIndex,
            // 				cartIndex: cartIndex,
            // 				siteId: siteItem.siteId,
            // 				cartId: item.cart_id
            // 			})
            // 		}
            // 	})
            // })

            this.checkedGoods.forEach((cart, index) => {
                cartIds.push(cart.cart_id)
                selectedItem.push({
                    siteIndex: 0,
                    cartIndex: index,
                    siteId: cart.site_id,
                    cartId: cart.cart_id
                })
            })

            if (cartIds.length == 0) {
                this.$message({
                    message: "请选择要删除的商品",
                    type: "warning"
                })
                return
            }

            this.$confirm("确定要删除选择的商品吗？", "提示信息", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(() => {
                this.$store
                    .dispatch("cart/delete_cart", {
                        cart_id: cartIds.toString()
                    })
                    .then((res) => {
                        if (res.code >= 0) {
                            selectedItem.forEach((selectedItem) => {
                                this.cartList.forEach((siteItem, siteIndex) => {
                                    siteItem.cartList.forEach((item, cartIndex) => {
                                        if (selectedItem.cartId == item.cart_id) {
                                            siteItem.cartList.splice(cartIndex, 1)
                                        }
                                        if (siteItem.cartList.length == 0) {
                                            this.cartList.splice(siteIndex, 1)
                                        }
                                    })
                                })
                            })
                            this.calculationTotalPrice()
                            this.$message({
                                type: "success",
                                message: "删除成功"
                            })
                        } else {
                            this.$message({
                                message: res.message,
                                type: "warning"
                            })
                        }
                    })
                    .catch((err) => {
                        this.$message.error(err.message)
                    })
            })
        },
        // 清空失效商品
        clearInvalidGoods() {
            this.$confirm("确认要清空这些商品吗？", "提示信息", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(() => {
                var cartIds = []
                this.invalidGoods.forEach((goodsItem) => {
                    cartIds.push(goodsItem.cart_id)
                })
                if (cartIds.length) {
                    this.$store
                        .dispatch("cart/delete_cart", {
                            cart_id: cartIds.toString()
                        })
                        .then((res) => {
                            if (res.code >= 0) {
                                this.invalidGoods = []
                                this.$message({
                                    type: "success",
                                    message: "删除成功"
                                })
                            } else {
                                this.$message({
                                    message: res.message,
                                    type: "warning"
                                })
                            }
                        })
                        .catch((err) => {
                            this.$message.error(err.message)
                        })
                }
            })
        },
        // 变更购物车数量
        cartNumChange(num, params) {
            // console.log(num)
            // console.log(params)
            if (num < 1 || !num) num = 1
            // 防止数量跳动
            this.modifyNum = 0
            this.$store
                .dispatch("cart/edit_cart_num", {
                    num,
                    cart_id: this.cartList[params.siteIndex].cartList[params.cartIndex].cart_id
                })
                .then((res) => {
                    if (res.code >= 0) {
                        this.cartList[params.siteIndex].cartList[params.cartIndex].num = num
                        this.calculationTotalPrice()
                    } else {
                        this.$message({
                            message: res.message,
                            type: "warning"
                        })
                        this.modifyNum = 1
                    }
                })
                .catch((err) => {
                    this.$message.error(err.message)
                    this.modifyNum = 1
                })
        },
        imageError(siteIndex, cartIndex) {
            this.cartList[siteIndex].cartList[cartIndex].sku_image = this.defaultGoodsImage
        },
        imageErrorInvalid(index) {
            this.invalidGoods[index].sku_image = this.defaultGoodsImage
        },
        handlePageChange() {
            this.shouhoulist()
        },
        // 获取购物车数据
        getMinimumAmount() {
          minimumAmount()
              .then((res) => {
                if (res.code >= 0) {
                    this.miniAmount = parseInt(res.data.value);
                }
                this.loading = false
              })
              .catch((res) => {
                 this.loading = false
              })
        }
    }
}
</script>

<style lang="scss" scoped>
.box {
    width: 100%;
    position: relative;

    h2 {
        font-size: 24px;
        color: $ns-text-color-black;
        padding-bottom: 5px;
        border-bottom: 1px solid #d9d9d9;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .switchTab {
            display: flex;
            align-items: center;

            .tab-item {
                font-size: $ns-font-size-lg;
                font-weight: 400;
                padding: 0 15px;
                border-right: 1px solid #e1e1e1;
                height: 20px;
                display: block;
                line-height: 20px;
                cursor: pointer;

                &:last-child {
                    border: 0;
                }
            }
        }
    }

    .form-row {
        display: flex;
        align-items: center;
        margin-top: 30px;

        .form-item {
            display: flex;
            align-items: center;
            color: $ns-text-color-black;

            span {
                margin-right: 10px;
            }

            .search-btn {
                height: 32px;
                width: 100px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: $ns-font-size-base;
                color: #ffffff;
                background-color: $base-color;
                margin-left: 10px;

                img {
                    width: 14px;
                    height: 14px;
                    margin-right: 5px;
                }
            }
        }
    }

    .table {
        width: 100%;
        margin-top: 30px;
    }

    .el-pagination {
        margin-top: 30px;
    }

    .delete {
        color: $base-color;
        cursor: pointer;
    }

    .controller {
        display: flex;
        margin-top: 30px;
        align-items: center;

        .controller-left {
            width: 100%;
            height: 40px;
            border: 1px solid #d9d9d9;
            background-color: $base-color-gray;
            display: flex;
            align-items: center;
            position: relative;
            box-sizing: border-box;
            padding-left: 20px;

            .control-btn {
                height: 32px;
                padding: 0 20px;
                border-radius: 14px;
                font-size: $ns-font-size-base;
                color: #ffffff;
                background-color: $base-color;
                margin-left: 20px;
                border: none;

                &.delete {
                    color: $ns-text-color-black;
                    background-color: transparent;
                    cursor: pointer;
                }
            }

            .member_price {
                position: absolute;
                right: 20px;
                top: 0;
                line-height: 40px;
                font-size: $ns-font-size-base;
                color: $ns-text-color-black;

                span {
                    color: red;
                    font-size: 24px;
                    font-weight: bold;
                    margin: 0 3px;
                }
            }
        }

        .controller-right {
            width: 280px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .el-button {
                padding: 0;
                width: 130px;
                height: 40px;
                border: 0;
                display: flex;
                align-items: center;
                color: #ffffff;
                justify-content: center;
                font-size: $ns-font-size-lg;
                background-color: $base-color;
                border-radius: 0;
                // opacity: .7;

                &.is-disabled {
                    opacity: 0.7;
                }

                &.green {
                    background-color: #2d9842;
                }

                &.red {
                    opacity: 1;
                }
            }
        }
    }
}
.top1 {
    width: 1240px !important;
}
</style>
